import { urlParts } from "./config";
import Cookies from "js-cookie";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL_PHP;
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.transformRequest = (data, headers) => {
	headers["Authorization"] = Cookies.get("FrontendSessionKey");
	return data;
}
export const php = axios.create({
  baseURL: process.env.BASE_URL_PHP,
  headers: {
		accept: "application/json, multipart/form-data",
		'Content-Type': "application/json"
  },
  transformRequest: (data, headers) => {
    console.log('transformRequest', data)
    headers["Authorization"] = Cookies.get("FrontendSessionKey");
    return JSON.stringify(data);
  }
});

export const asp = axios.create({
  baseURL: process.env.BASE_URL_ASP,
  headers: {
    accept: "application/json",
		'Content-Type': "application/json"
  },
  transformRequest: (data, headers) => {
    headers["Authorization"] = Cookies.get("FrontendSessionKey");
    return data;
  }
});

export const createPhpCrud = (endpoint) => ({
  async get(data = null) {
    const res = await php.get(`${endpoint}`, { params: data });
    return res.data;
  },
  async getById(id, data=null) {
    const res = await php.get(`${endpoint}/${id}`, { params: data });
    return res.data;
  },
  async update(id, data) {
    const res = await php.put(`${endpoint}/${id}`, data);
    return res.data;
  },
  async create(data) {
    const res = await php.post(`${endpoint}`, data);
    return res.data;
  },
  async delete(id) {
    const res = await php.delete(`${endpoint}/${id}`);
    return res.data;
  }
});

export const body = {
  dashboard: {
    async get(filter) {
      const res = await php.get(`/dashboard`, { params: filter });
      return res.data;
    }
  },
	analytics: {
		async getTransactions() {
			const res = await php.get(`/analytics/transactions`);
      return res.data;
		},
		async getArticleTransactions(lockerId, lockerArticleId) {
			const res = await php.get(`/analytics/transactions?lockerId=${lockerId}&lockerarticleid=${lockerArticleId}`);
      return res.data;
		}
	},
  secureCloud: {
    async get() {
      const res = await php.get(`/admin/secure-cloud`);
      return res.data;
    }
  },
  lockers: {
    ...createPhpCrud("/cabinet"),
    async getTransactions(id) {
      const res = await php.get(`/cabinet/${id}/transaction`);
      return res.data;
    },
    async getTransactionsByFilter(id, data) {
      const res = await php.post(`/cabinet/${id}/transaction-by-filter`, data);
      return res.data;
    },
    async getArticles(id) {
      const res = await php.get(`/cabinet/${id}/article`);
      return res.data;
    },
    async deleteArticle(lockerId, articleId) {
      const res = await php.delete(`/cabinet/${lockerId}/article/${articleId}`);
      return res.data;
    },
    async updateArticle(lockerId, data) {
      const res = await php.put(`/cabinet/${lockerId}/article`, data);
      return res.data;
    },
    async searchArticle(lockerId, str) {
      const res = await php.post(`/cabinet/${lockerId}/searcharticle`, { SearchString: str });
      return res.data;
    },
    async addArticle(lockerId, articleId) {
      const res = await php.post(`/cabinet/${lockerId}/article/${articleId}`);
      return res.data;
    },
    async getUsers(lockerId) {
      const res = await php.get(`/cabinet/${lockerId}/user`);
      return res.data;
    },
    async addUser(lockerId, userId) {
      const res = await php.post(`/cabinet/${lockerId}/user/${userId}`);
      return res.data;
    },
    async getUserTransactions(lockerId, userId) {
      const res = await php.get(`/cabinet/${lockerId}/user/${userId}/article`);
      return res.data;
    },
    async deleteUser(lockerId, userId) {
      const res = await php.delete(`/cabinet/${lockerId}/user/${userId}`);
      return res.data;
    },
    async searchUser(lockerId, str) {
      const res = await php.post(`/cabinet/${lockerId}/searchuser`, { SearchString: str });
      return res.data;
    },
    async getStatistics(lockerId, data) {
      const res = await php.post(`/cabinet/${lockerId}/stat`, data);
      return res.data;
    },
    async getOrderpoints(lockerId) {
      const res = await php.get(`/cabinet/${lockerId}/orderpoint`);
      return res.data;
    }
  },
  articles: {
    ...createPhpCrud("/admin/article"),
    async uploadImage(id, image) {
      const data = new FormData();
      data.append("image", image, image.name);
      return axios.post(`/admin/article/${id}/upload`, data);
		},
		async deleteImage(id) {
			const res = await php.delete(`/admin/article/${id}/upload`);
      return res.data;
		},
		async search(str) {
			const res = await php.post(`/admin/article/search`, { searchKey: str });
      return res.data;
		},
    async archiveOrUnarhiveArticle(id) {
			const res = await php.put(`/admin/article/${id}/archive-or-unarchive`);
      return res.data;
		}
  },
  devices: {
    ...createPhpCrud("/admin/device")
  },
  lockersV2: {
    ...createPhpCrud("/admin/locker"),
    async createLocker(data) {
      	return axios.post(`/admin/locker`, data).catch(function (error) {
			return {'data' : {'success': 0, 'errorcode': error.response.status}};
		});
	},
    async updateLocker(id ,data) {
		return axios.post(`/admin/locker/${id}`, data).catch(function (error) {
			return {'data' : {'success': 0, 'errorcode': error.response.status}};
		});
	},

	async addProvision(id, provisionCode) {
		return php.put(`/admin/locker/${id}/add-provision`, {'provisionCode': provisionCode}).catch(function (error) {
			return {'data' : {'success': 0, 'errorcode': error.response.status}};
		});
	},
	async restartLocker(id) {
		return php.put(`/admin/locker/${id}/restart`).catch(function (error) {
			return {'data' : {'success': 0, 'errorcode': error.response.status}};
		});
	}

  },
  resellerCustomers: {
    ...createPhpCrud("/admin/reseller-customers"),
  },
  articlegroups: {
    ...createPhpCrud("/admin/articlegroup"),
    async uploadImage(id, image) {
      const data = new FormData();
      data.append("image", image, image.name);
      return axios.post(`/admin/articlegroup/${id}/upload`, data);
    },
    async deleteImage(id) {
			const res = await php.delete(`/admin/articlegroup/${id}/upload`);
      return res.data;
		},
		async searchArticle(id, str) {
			const res = await php.post(`admin/articlegroup/${id}/articlesearch`, { SearchString: str });
      return res.data;
		}
  },
  users: {
    ...createPhpCrud("/admin/user")
  },
  pricePlans: {
    ...createPhpCrud("/price-plans"),
    async makeDefault(pricePlanId) {
      const res = await php.put(`price-plans/${pricePlanId}/make-default`);
      return res.data;
    },
  },
  discounts: {
    ...createPhpCrud("/customer-discounts"),
  },
  pricePlanTiers: {
    async get(pricePlanId) {
      const res = await php.get(`price-plans/${pricePlanId}/tiers`);
      return res.data;
    },
    async store(pricePlanId, data) {
      const res = await php.post(`price-plans/${pricePlanId}/tiers`, data);
      return res.data;
    },
    async update(pricePlanId,pricePlanTierId, data) {
      const res = await php.put(`price-plans/${pricePlanId}/tiers/${pricePlanTierId}`, data);
      return res.data;
    },
    async delete(pricePlanId,pricePlanTierId) {
      const res = await php.delete(`price-plans/${pricePlanId}/tiers/${pricePlanTierId}`);
      return res.data;
    }
  },
  billingStatements: {
    async get() {
      const res = await php.get(`billing-statements`);
      return res.data;
    },
    async downloadPDF(id) {
      const res = await php.post(`billing-statements/${id}/download`);
      return res.data;
    },
    async creditStatement(id){
      const res = await php.post(`billing-statements/${id}/credit`);
      return res.data;
    },
    async generateBill(){
      const res = await php.post(`billing-statements/generate-bills`);
      return res.data;
    }
  },
  billingLockers: {
    async get() {
      const res = await php.get(`billing-lockers`);
      return res.data;
    },
    async update(id, data) {
      const res = await php.put(`billing-lockers/${id}`, data);
      return res.data;
    },
  },
  materialForecast: {
    async get(data = {}) {
      const res = await php.get(`material-forecast`, {params: data});
      return res.data;
    },
  },
  currencies: {
    async get() {
      const res = await php.get(`currencies`);
      return res.data;
    },
  },
  resellers: createPhpCrud("/reseller"),
  customers: createPhpCrud("/customer"),
    authenticate: async () => {
      return php.get("/currentuser").then((res) => res.data);
    },
  async login(data, notifications) {
    return new Promise((resolve, reject) => {
      php
        .post("login", data)
        .then((res) => {
          if (res.data.success == 1) {
            Cookies.set("FrontendSessionKey", res.data.SessionKey)
            resolve(res.data);
          } else {
            notifications.push(
              {
                title: "Wrong credentials",
                msg: "Du har angett felaktiga inloggninsuppgifter"
              },
              "error",
              false,
              false
            );
            reject(res.data);
          }
        })
        .catch((_) => {
          notifications.push(
            {
              title: "Connections problems",
              msg: "Någonting gick fel, kontrollera din internetanslutning."
            },
            "error",
            false,
            false
          );
        });
    });
  },
  async logout() {
    return php.post("logout").then((res) => {
      Cookies.remove("FrontendSessionKey");
      return res;
    });
  },
  php,
  asp,
  filterURLs: {
    materialForecast: 'material-forecast'
  }
};


// const api = {
//   install(Vue) {
//     Vue.prototype.$http = function(url, data = {}, type = "GET", headers = {}, xhrFields = {}) {
//       return ajax(url, type, data, headers, xhrFields)
//         .then((res, t, headers) => {
//           return res, t, headers;
//         })
//         .fail(() => {
//         });
//     };
//   },
//   };
  
//   export default api;