<template>
    <div>
        <Navbar :items="routes" />
           <GlobRow>
               <GlobCol :medium="12">
                   <GlobXpanel :title="$t('administration.reseller_customers.customers')">
                       <button class="button button--margin button--small" @click="showAddOrUpdateModal = true">{{ $t('general.create_new') }}</button>
                       <InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
                   <GlobTable
                       :enableRenderFunctions="true"
                       :maxHeight="50"
                       :data="{
                           head : customerListHead,
                           body : filteredCustomerList
                       }"
                       :actions="actions"
                   />
                   </GlobXpanel>
               </GlobCol>
           </GlobRow>

           <GlobModal :width="600" 
                :widthInPx="true"
                :title="form.Id ? $t('administration.reseller_customers.update_customer') : $t('administration.reseller_customers.create_customer')" 
                @close="closAddOrUpdateModal()"
                :show="showAddOrUpdateModal && !showDeleteModal"
                :isCRUD="true"
                 v-if="showAddOrUpdateModal">

                 <GlobRow>
                    <GlobSpinner :isLoading="isLoadingCRUDModal" />
					<GlobCol :medium="6">
                        <GlobLabel :label="$t('administration.reseller_customers.customer_name')+'*'">
                            <InputDynamic
                            :fullWidth="true"
                            :type="'text'"
                            :placeholder="$t('administration.reseller_customers.customer_name')"
                            v-model="form.CustomerName"
                            />
                        </GlobLabel>
                    </GlobCol>
                    <GlobCol :medium="6">
                        <GlobLabel :label="$t('administration.reseller_customers.organisation_number')">
                            <InputDynamic
                            :fullWidth="true"
                            :type="'text'"
                            :placeholder="$t('administration.reseller_customers.organisation_number')"
                            v-model="form.OrganisationNumber"
                            />
                        </GlobLabel>
                    </GlobCol>
                    <GlobCol :medium="12">
                        <button @click="save" class="button" :disabled="!form.CustomerName">{{$t('general.button.save')}}</button>
                        <button @click="showDeleteModal = true" class="button button--red" v-if="form.Id">{{$t('general.button.delete')}}</button>
                    </GlobCol>
                </GlobRow>
            </GlobModal>



            <!-- Delete confirm modal -->
                <GlobConsequence :show="showDeleteModal" 
                    @close="showDeleteModal=false"  
                    @confirm = "deleteCustomer()"
                    :title="$t('administration.reseller_customers.delete_customer')" 
                    :message="$t('administration.reseller_customers.delete_confirm')"  />
   </div>
   </template>
   
   <script>
   import { onMounted, computed, ref } from "vue";
   import { useApi } from "@/plugins/api";
   import { useRouter } from "vue-router";
   import Navbar from "@/views/Admin/Navbar.vue";
   import { routes } from "@/views/Admin/config";
   import { useI18n } from 'vue-i18n'
   import { useNotifications } from "@/plugins/notifications";
import GlobLabel from "../../../components/globals/glob-label.vue";
   
   export default {
   components: {
       Navbar
   },
   
    setup() {
   
       const { tm } = useI18n()
       const api = useApi();
       const router = useRouter();
       const endpoint = computed(() => api.resellerCustomers);
       const notifications = useNotifications();
       
       const loaded = ref(false);
       const isLoadingCRUDModal = ref(false);
       const showDeleteModal = ref(false);
       const searchString = ref('');
       const customers  = ref([]);
       const customerListHead = computed(() => 
           [
               {
                   Alias: "CustomerName",
                   Value: tm('administration')['reseller_customers']['fields']['name']
               },
                {
                     Alias: "OrganisationNumber",
                     Value: tm('administration')['reseller_customers']['fields']['organisation_number']
                }
           ]
       
        );

        const showAddOrUpdateModal = ref(false);
        const form = ref({
            Id: null,
            CustomerName: null,
            OrganisationNumber: null,
        });
       
       
       const actions = computed(() => [
               {
                   name : tm('general')['button']['edit'],
                   color: "green",
                   action: (obj) => {
                        form.value = Object.assign({}, obj);
                        showAddOrUpdateModal.value = true;
                   }
               },
           ]
       )

       
        onMounted(async() => {
             loadCustomers();
        });
   
       const loadCustomers = async() => {
           endpoint.value.get().then(function(res){
              customers.value = res.customers;
           })
       }
   
       const filteredCustomerList = computed(() => {
          return searchString.value.length > 0 ? customers.value.filter(i => i.CustomerName && i.CustomerName.toLowerCase().match(searchString.value.toLowerCase())) : customers.value
       });
   

       //CRUD 

       const save = () => {
           if(form.value.Id){
                isLoadingCRUDModal.value = true;
                endpoint.value.update(form.value.Id, form.value).then(function(res){
                    if(res.success){
                        loadCustomers();
                        closAddOrUpdateModal();
                        notifications.push(
                                {
                                    msg: tm('administration')['reseller_customers']['create_success']
                                },
                                "success",
                                false
                        );
                    }

                    if(res.errors){
                        for (const item in res.errors) {
                            notifications.push(
                                {
                                    msg: tm(res.errors[item][0])
                                },
                                "error",
                                false
                            );
                            break;
                        }
                    }
                   isLoadingCRUDModal.value = false;
               })
           }else{
                isLoadingCRUDModal.value = true;
                endpoint.value.create(form.value).then(function(res){
                    if(res.success){
                        loadCustomers();
                        closAddOrUpdateModal();
                        notifications.push(
                                {
                                    msg: tm('administration')['reseller_customers']['update_success']
                                },
                                "success",
                                false
                            );
                    }

                    if(res.errors){
                        for (const item in res.errors) {
                            notifications.push(
                                {
                                    msg: tm(res.errors[item][0])
                                },
                                "error",
                                false
                            );
                            break;
                        }
                    }
                   isLoadingCRUDModal.value = false;
               })
           }
       }

       const deleteCustomer = () => {
           isLoadingCRUDModal.value = true;
           showDeleteModal.value = false;
           endpoint.value.delete(form.value.Id).then(function(res){
               loadCustomers();
               closAddOrUpdateModal();
               notifications.push(
                        {
                            msg: tm('administration')['reseller_customers']['delete_success']
                        },
                        "success",
                        false
					);
               isLoadingCRUDModal.value = false;
           })
       }

       const closAddOrUpdateModal = () => {
           showAddOrUpdateModal.value = false;
           form.value = {
               Id: null,
               CustomerName: null,
               OrganisationNumber: null,
           }
       }

       return {
           //Methods
           loadCustomers,
           //Variables
           endpoint,
           filteredCustomerList,
           customers,
           customerListHead,
           searchString,
           actions,
           routes,
           loaded,
           //Add or Update Modal
            showAddOrUpdateModal,
            form,
            save,
            isLoadingCRUDModal,
            deleteCustomer,
            showDeleteModal,
            closAddOrUpdateModal
       };
     }
   };
   </script>