export { createNav } from './config'
import { createRouter, createWebHistory } from "vue-router";
import * as collections from './modules/index'
import { standard, modules, createRoutes } from './config';
import Home from "@/views/Home";
import Login from "@/root/Login";

//Billing
import Billing from '@/views/Billing/index.vue';
import BillingStatement from '@/views/Billing/Statement/index.vue';

//Sandbox
import Sandbox from "@/views/Sandbox/index";
import Tables from "@/views/Sandbox/Tables"
import Charts from "@/views/Sandbox/Charts"
import Notifications from "@/views/Sandbox/Notifications"
import Misc from "@/views/Sandbox/Misc"

import * as crud from "./modules/crud"

const { statistics, planing, storage, admin } = collections;
const routesList = [
	{
		name: "Home",
    path: "/",
		component: Home,
    nav: {
			name: 'general.start',
			icon: "home",
		}
  },
  //Billing routes
  {
	name: "Billing",
	path: "/billing",
	component: Billing,
	nav: {
			name: 'general.billing',
			icon: "pallet",
	},
	meta: {
		access: ['superadmin', 'admin']
	}
  },
  {
	name: "ResellerBilling",
	path: "/statements",
	component: BillingStatement,
	nav: {
			name: 'general.billing',
			icon: "pallet",
	},
	meta: {
		access: ['reseller']
	}
  },
  {
	name: "BillingStatement",
	path: "/billing/statements",
	component: BillingStatement,
	isCRUD: true,
	nav: {
			name: 'general.billing',
			icon: "pallet",
	},
	meta: {
		access: ['superadmin', 'admin']
	}
  },
  
  {
		name: "Login",
    path: "/login",
		component: Login,
    meta: {
			panelMode: true
    }
	},
	statistics,
	planing,
	storage,
	admin,
  {
		name: "Sandbox",
    path: "/sandbox",
		component: Sandbox,
		nav: {
			name: 'Sandbox',
			icon: 'fighter-jet'
		},
		children: [
			{
				path: '/sandbox/tables',
				component: Tables,
				nav: {
					name: 'Tabeller'
				}
			},
			{
				path: '/sandbox/charts',
				component: Charts,
				nav: {
					name: 'Grafer'
				}
			},
			{
				path: '/sandbox/notifications',
				component: Notifications,
				nav: {
					name: 'Notifikationer'
				}
			},
			{
				path: '/sandbox/misc',
				component: Misc,
				nav: {
					name: 'Övrigt'
				}
			}
		]
  }
];

/*  */
const {article, articleGroup, device , resellerCustomers, locker, pricePlan, customerDiscount, secureCloud} = crud;

const crudRoutes = [...article, ...articleGroup, ...device, ...resellerCustomers, ...locker, ...pricePlan, ...customerDiscount, ...secureCloud ] ;

export const routes = [...routesList, ...crudRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: createRoutes(routes, [...standard, ...modules])
});

export default router;
