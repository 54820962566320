<template>
  <div class="kis-multi-select">
		<VueMultiselect
		ref="multiSelectRef"
		:class="enableSearchAndAdd ? 'multi-select-search-and-add' : ''"
		v-model="activeOptions"
		@select="(selectedOption, id) => optionSelected(selectedOption, id)"
		@update:model-value="updateSelected"
		:preserve-search="true"
    	:clear-on-select="false"
		:multiple="true"
		:close-on-select="true"
		:track-by="!isFilter ? 'value' : ''"
		:label="!isFilter ? 'name': ''"
		:taggable="enableSearchAndAdd"
		:placeholder="enableSearchAndAdd ? $t('general.multi_select.search_or_add_tag') : ( localPlaceholder ? localPlaceholder : $t('general.select'))" 
    	@tag="addTag"
		@search-change="(searchKey) => searchChange(searchKey)"
		:showNoOptions="false"
		 @close="closeEventHandler"
		:options="dataList">
		</VueMultiselect>
		
		<template v-if="enableSearchAndAdd && activeSearchKey.length">
			<div class="search-result-area">
				 <template v-if="!searchResult.length">
					<div class="empty-search-result-message">
						{{$t('general.multi_select.no_tags_found')}}
					</div>
				 </template>
				 <template v-else>
					<span v-for="(tag, index) in searchResult" :key="'search-result-tag-'+tag.value" class="multiselect__tag" @click="addSearchedTag(index)">
						{{tag.name}}
						<i class="multiselect__tag-icon"></i>
					</span>
				 </template>
			</div>
		</template>
  </div>
</template>

<script>
import {watch, ref, toRaw, onMounted, computed } from "vue";
import VueMultiselect from 'vue-multiselect'
import { props } from './common';
export default {
  props: props,
  components: { VueMultiselect },
  setup(props, {emit}){
	  const multiSelectRef = ref(null);
	  const activeOptions = ref([]);
	  const dataList = ref(props.data);
	  const activeSearchKey = ref('');
	  const searchResult = ref([]);
	  const active = ref(false);

	  const updateSelected = () => {
		  if(props.isFilter){
			  return  emit('updateValue', activeOptions.value)
		  }
		  let ids = [];
		  activeOptions.value.map((item) => {
				ids.push(item.value);
		  })
		 emit('updateValue', ids)
	  }

	  onMounted(() => {
		  presetSelelectedOptions()
	  })

		watch(() => props.selected, (selection, prevSelection) => { 
			presetSelelectedOptions()
		})

		watch(() => props.data, (selection, prevSelection) => { 
			dataList.value = props.data;
		})

		watch(() => activeOptions.value, () => {
			searchChange(activeSearchKey.value);
		})
	
	  const presetSelelectedOptions = () => {
		  let preselectedItems = [];
		  if(props.selected && props.selected.length){

			  if(!props.enableSearchAndAdd){
				props.selected.map((item) => {
				  	preselectedItems.push(dataList.value.find(i=> i.value == item));
			  	});
			  }else{
				  preselectedItems = props.selected;
			  }

			 
			  activeOptions.value = toRaw(preselectedItems);
		  }else{
			  activeOptions.value = [];
		  }
	  }

	  const optionSelected = (selectedOption, id) => {
			updateSelected();
	  }

	  const addTag = (tag) => {
		  activeOptions.value.push({
			  'name': tag,
			  'value': tag
		  })
		  updateSelected();
	  }
	
	  const searchChange = (searchKey) => {
		  emit('searchKey', searchKey)
		  activeSearchKey.value = searchKey;
		  if(props.enableSearchAndAdd){
			  if(searchKey.length > 1){
				  let selectedOptionsIds =  activeOptions.value.map( i=> i.value )
				  props.searchAction(searchKey, selectedOptionsIds).then((data) => {
					searchResult.value = data
				  });
			  }else if(!searchKey.length){
				  searchResult.value = [];
			  }
		  }
	  }

	  

	  const addSearchedTag = (tagIndex) => {
		  activeOptions.value.push(searchResult.value[tagIndex])
		  searchResult.value.splice(tagIndex, 1)
		  updateSelected();
	  }

	  const closeEventHandler = () => {
		  if(multiSelectRef.value.search && props.enableSearchAndAdd){
			  multiSelectRef.value.isOpen = true;
		  }else{
			   multiSelectRef.value.isOpen = false;
		  }
	  }

	  const localPlaceholder = computed(() => {
		  return props.placeholder;
	  })

	  return {
		  activeOptions,
		  updateSelected,
		  optionSelected,
		  dataList,
		  addTag,
		  addSearchedTag,
		  searchResult,
		  searchChange,
		  multiSelectRef,
		  closeEventHandler,
		  activeSearchKey,
		  ...props,
		  localPlaceholder
	  }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.kis-multi-select{

.multiselect__tags {
	min-height: 33px !important;
	padding: 4px 36px 0px 8px;
}
.multiselect__tags-wrap{
	margin-top: -5px;
}
.multiselect__tag {
    font-size: .8rem;
    position: relative;
    display: inline-block;
    padding: 4px 25px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
	margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: -1px;
    bottom: 0;
    font-weight: 700;
    font-style: initial;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
}
.multiselect__select{
	top: -8px;
	margin-top: 5px;
}

.multiselect__placeholder {
  margin-bottom: 0px; 
  padding-top: 0px;
}

.multiselect__input{
	border: none !important;
	padding: 0 0 0 0 !important;
}

.multi-select-search-and-add{
	.multiselect__content-wrapper{
		display: none !important;
	}
}

.multiselect__tag-icon::after {
	font-size: 16px;
}

.search-result-area{
	border: 1px solid $kits-lightInputBorder;
	min-height: 100px;
	max-height: 200px;
	border-radius: 6px;
	padding: 8px;
    margin-top: 0.5rem;

	.multiselect__tag{
		cursor: pointer;
	}

	.empty-search-result-message{
		text-align: center;
		padding: 2rem;
		font-size: 1.2rem;
		opacity: .4;
	}

	.multiselect__tag-icon::after {
		content: "+";
		color: white;
	}
}

}

</style>