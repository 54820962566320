<template>
    <GlobRow>
		<GlobSpinner :isLoading="isLoading" />
		<GlobCol :medium="12" class="home-welcome-messge">
            <p class="welcome">{{$t('general.welcome')}},</p> <p class="user-name">{{user.Name}}</p>
        </GlobCol>
		<GlobCol :medium="4">
			<label>
				{{ $t('home.from_to') }}
				<InputDynamic
					:type="'daterange'"
					class="kis-mt-1"
					:fullWidth="true"
					v-model="filter.dateRange"
					:placeholder="$t('home.from_to')"
					>
				</InputDynamic>
			</label>
		</GlobCol>
		<GlobCol :medium="4">
				<label>
					{{ $t('home.customer') }}
					<InputDynamic
						:type="'multiselect'"
						:fullWidth="true"
						v-model="filter.customers"
						:data="customers"
						:placeholder="$t('home.select_customer')"
						>
					</InputDynamic>
				</label>
		</GlobCol>
      <GlobCol :medium="8">
         <TransactionChart :transactions="transactions" />
      </GlobCol>
	  <GlobCol :medium="4">
			<TopMovers :data="topMovers"></TopMovers>
	  </GlobCol>
	  <GlobCol :medium="8">
			<NeedAttention :data="needAttention"></NeedAttention>
	  </GlobCol>	
    </GlobRow>
  </template>
  
  <script setup>
  import { useApi } from "@/plugins/api";
  import { reactive, computed, ref, onMounted, watch } from "vue";
  import { useStore } from 'vuex';
  
  import TransactionChart from "./Components/TransactionChart.vue";
  import TopMovers from "./Components/TopMovers.vue";
  import moment from "moment";
import NeedAttention from "./Components/NeedAttention.vue";
  
  // Store and API
  const api = useApi();
  const store = useStore();
  const user = computed(() => store.state.USER);
  
  // State
  const isLoading = ref(false);
  const transactions = ref(null);
  const topMovers = ref([]);
  const customers = ref([]);
  const needAttention = ref([]);
  
  // Filters
  const filter = reactive({
	dateRange:  [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()],
	customers: []
  });
  
  // Fetch Data
  const getData = async (includeDependencies = false) => {
	isLoading.value = true;
	await api.dashboard.get({
		includeDependencies: includeDependencies,
		customers: filter.customers,
		from: filter.dateRange && filter.dateRange[0] ? moment(filter.dateRange[0]).format('YYYY-MM-DD') : null,
		to: filter.dateRange && filter.dateRange[1] ? moment(filter.dateRange[1]).format('YYYY-MM-DD') : null
	}).then((res) => {

	  if(includeDependencies){
		customers.value = res.dependencies.customers.map((i) => ({
		  value: i.Id,
		  name: i.CustomerName
		}));
	  }

	  transactions.value = res.transactions.reduce((a, c) => {
		if (!a.find((i) => i.name === c.Locker)) {
		  a.push({
			name: c.Locker,
			data: res.transactions
			  .filter((item) => item.Locker === c.Locker)
			  .map((i) => ({
				date: new Date(i.TransactionDate),
				value: i.TransactionAmount
			  }))
		  });
		}
		return a;
	  }, []);

	  topMovers.value = res.topMovers;
	  needAttention.value = res.needAttention;
	});
	isLoading.value = false;
  };
  
  // Lifecycle
  onMounted(() => {
	getData(true);
  });

  watch(filter, (newVal) => {
	getData();
  });
  </script>
  
  <style lang="scss">
  .done {
    text-decoration: line-through;
  }
  
  .home-welcome-messge{
    color: $kits-steelGrayV1;
    .user-name{
      font-weight: 500;
    }
    p{
      margin: 0;
      display: inline-block;
      font-family: 'Inter';
      font-style: normal;
      font-size: 30px;
      line-height: 36px;
    }
    .welcome{
      font-weight: 100;
    }
  }
  </style>
  