
<template>
	<div>
		<GlobSpinner :isLoading="isLoading"></GlobSpinner>
		<!-- Filter section start-->
		<GlobRow class="tab filter-section">
			<GlobCol :medium="4" class="mas-mt-3">
				<label>
					{{ $t('storage.transaction.from_to') }}
					<InputDynamic
						:type="'daterange'"
						:fullWidth="true"
						v-model="filter.dateRange"
						:placeholder="$t('storage.transaction.from_to')"
						>
					</InputDynamic>
				</label>
			</GlobCol>
			<GlobCol :medium="4">
					<label>
						{{ $t('storage.transaction.article') }}
						<InputDynamic
							:type="'multiselect'"
							:fullWidth="true"
							v-model="filter.articles"
							:data="articleList"
							:placeholder="$t('storage.transaction.type_to_search_articles')"
							@searchKey="(searchKey) => searchArticle(searchKey)"
							>
						</InputDynamic>
					</label>
			</GlobCol>
			<GlobCol :medium="3" class="filter-buttons">
				<button class="button" @click="getLockerTransactions()">{{ $t('general.button.filter') }}</button>
				<button class="button button--orange" @click="clearFilter">{{ $t('general.button.clear') }}</button>
			</GlobCol>
		</GlobRow>
		<!-- Filter section end-->
		<GlobTable style="max-width: 1200px;"
			:enableExcelExport="true"
			:exportExcelName="exportExcelName"
			:data="{
				body: body,
				head: head
			}"
		/>
	</div>
</template>

<script>
import { onMounted, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useApi } from '@/plugins/api';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
export default {
	props: {
		lockerName: {
			type: String,
			default: ''
		}
	},
	setup(props) {
		const { tm } = useI18n();
		const api = useApi();
		const router = useRouter();
		const isLoading = ref(false);
		const id = ref(router.currentRoute.value.params.id);


		const body = ref([]);
		const head = computed(() =>
			[
				{ Alias: 'ArticleName', Value: tm('storage.transaction.article') },
				{ Alias: 'Name', Value: tm('storage.transaction.name') },
				{ Alias: 'LastName', Value: tm('storage.transaction.last_name') },
				{ Alias: 'Amount', Value: tm('storage.transaction.amount') },
				{ Alias: 'Opened', Value: tm('storage.transaction.opened') },
				{ Alias: 'Closed', Value: tm('storage.transaction.closed') },
				{ Alias: 'Aborted', Value: tm('storage.transaction.aborted') },
			]
		);
		
		let filter = reactive({
			articles: [],
			dateRange: []
		});

		const currentDate = new Date();
		const articleList = ref([]);
		const loadedArticleList = ref([]);

		onMounted(() => {
			clearFilter();
		})

		const getLockerTransactions = () => {
			isLoading.value = true;
			api.lockers.getTransactionsByFilter(id.value,
				{
					articles: filter.articles,
					from: filter.dateRange && filter.dateRange[0] ? moment(filter.dateRange[0]).format('YYYY-MM-DD') : null,
					to: filter.dateRange && filter.dateRange[1] ? moment(filter.dateRange[1]).format('YYYY-MM-DD') : null
				}
			).then(res => {
				body.value = res.data;
				isLoading.value = false;
			})
		}

		const searchArticle = (searchKey) => {

			if (searchKey && searchKey.length > 2) {
				if (loadedArticleList.value && loadedArticleList.value[searchKey]) {
					articleList.value = loadedArticleList.value[searchKey]
				} else {
					api.articles.search(searchKey).then((res) => {
						let articles = res.articles.map((article) => {
							return {
								'value': article.Id,
								'name': article.Name
							}
						});
						articleList.value = articles;
						loadedArticleList.value[searchKey] = articleList.value;
					})
				}
			}
		}


		const clearFilter = () => {
			filter.dateRange = [new Date(new Date().setDate(new Date().getDate() - 14)), currentDate]
			filter.articles = [];
			getLockerTransactions();
		}

		const exportExcelName = computed(() => {
			if(filter.dateRange && filter.dateRange[0] && filter.dateRange[1]) {
				return props.lockerName+` transactions (` + moment(filter.dateRange[0]).format('YYYY-MM-DD') + ` - ` + moment(filter.dateRange[1]).format('YYYY-MM-DD') + `)`;
			} else {
				return props.lockerName+` transactions`;
			}
		});

		return {
			body,
			head,
			getLockerTransactions,
			isLoading,
			filter,
			searchArticle,
			articleList,
			clearFilter,
			exportExcelName
		}
	}
}
</script>