<template>
	<Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height"/>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from './molecules/Wrapper.vue';
import { generateId } from '@/services/utils';
import { ref, onMounted, onUnmounted, watch } from 'vue';
export default {
	components: {
		Wrapper
	},
	props: {
		data: {
			type: Array,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		axisY: {
			type: String,
			required: true
		},
		axisX: {
			type: String,
			required: true
		},
		color: {
			type: String,
			required: false
		},
		xPanel: {
			required: false,
			default: true,
			type: Boolean
		},
		height: {
			type: Number,
			required: false
		}
	},
	setup(props) {
		let chart = null;
		const containerId = generateId(props.name);
		const createSeries = () => {
			if(!props.data || props.data.length === 0) {
				return;
			}
			let series = null;
			props.data.forEach((item) => {
				let series = chart.series.push(new am4charts.LineSeries());
				series.data = item.data;
				series.dataFields.valueY = props.axisY;
				series.dataFields.dateX = props.axisX;
				series.name = item.name;
				series.tooltipText = "{value}";
				series.tooltip.pointerOrientation = "vertical";

				let segment = series.segments.template;
				segment.interactionsEnabled = true;

				let hoverState = segment.states.create("hover");
				hoverState.properties.strokeWidth = 3;

				let dimmed = segment.states.create("dimmed");
				dimmed.properties.stroke = am4core.color("#dadada");

				segment.events.on("over", (event) => {
					processOver(event.target.parent.parent.parent);
				});

				segment.events.on("out", (event) => {
					processOut(event.target.parent.parent.parent);
				});
			});
			chart.cursor = new am4charts.XYCursor();
			chart.cursor.snapToSeries = series;
			return series;
		}

		const processOut = (hoveredSeries) => {
			chart.series.each((series) => {
				series.segments.each((segment) => {
					segment.setState("default");
				});
				series.bulletsContainer.setState("default");
			});
		}

		const processOver = (hoveredSeries) => {
			hoveredSeries.toFront();
			hoveredSeries.segments.each((segment) => {
				segment.setState("hover");
			});

			chart.series.each((series) => {
				if (series != hoveredSeries) {
					series.segments.each((segment) => {
						segment.setState("dimmed");
					});
					series.bulletsContainer.setState("dimmed");
				}
			});
		}

		const init = () => {
			am4core.ready(() => {
				am4core.useTheme(am4themes_animated);
				am4core.options.onlyShowOnViewport = true;
				chart = am4core.create(containerId, am4charts.XYChart);
				chart.svgContainer.autoResize = false;

				// Create axes
				var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
				dateAxis.groupData = true;
				dateAxis.renderer.minGridDistance = 60;

				var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				createSeries();

				// Create series
				chart.legend = new am4charts.Legend();
				chart.legend.position = "right";
				chart.legend.scrollable = true;
				chart.legend.itemContainers.template.events.on("over", (event) => {
					processOver(event.target.dataItem.dataContext);
				});
				chart.legend.itemContainers.template.events.on("out", (event) => {
					processOut(event.target.dataItem.dataContext);
				});

				//chart.scrollbarY = new am4core.Scrollbar();
				chart.scrollbarX = new am4core.Scrollbar();
			});
		}

		onMounted(() => {
			init();
		})

		onUnmounted(() => {
			chart.dispose();
		})

		watch(() => props.data, (newValue) => {
			if (chart) {
				init();
			}
		}, { deep: true });

		return {
			containerId
		}
	},
};
</script>
