
import ResellerCustomer from "@/views/crud/ResellerCustomer/ResellerCustomers.vue";

export const resellerCustomers = [{
  path: "/admin/view/reseller-customers",
  component: ResellerCustomer,
  isCRUD: true,
  name: "ResellerCustomer",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Översikt" }]
  },
}]
