<template>
   <GlobXpanel :title="$t('home.top_movers.heading')">
        <GlobTable
            :maxHeight="50"
            :data="{
              body: data,
              head: head,
            }"
          />
   </GlobXpanel>
 </template>
 
 <script setup>

    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    const { tm } = useI18n();
    
    const props = defineProps({
    data: {
        type: Array,
        required: true
    }
    });

    const head = computed(() => {
        return [
            { Alias: "ArticleName", Value: tm('home.top_movers.article') },
            { Alias: "ArticleNumber", Value: tm('home.top_movers.article_number') },
            { Alias: "ConsumedAmount", Value: tm('home.top_movers.amount') }
        ];
    });
 
 </script>