<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="quickSelected ? 7 :12">
				<GlobXpanel :title="$t('administration.article.articles')">
					<button @click="showCreateArticleModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>

					<div class="filter-by-archived">
						<label class="title">{{$t('general.show_archived')}}</label>
						<InputSwitch  :toggles="showarchiveToggles" v-model="showArchived"/>
					</div>
					<GlobTable
						:enableRenderFunctions="true"
						:itemRenderFunctions="renderFunctions"
						:maxHeight="50"
						:data="{
							head : articleListHead,
							body : filteredArticles
						}"
						:actions="actions"
						:enablePagination="true"
						:paginationCurrentPage="paginationCurrentPage"
						:paginationLastPage="paginationLastPage"
						@jumpToPage = "(pageNumber) =>jumpToPage(pageNumber)"
					/>
				</GlobXpanel>
			</GlobCol>
			<GlobCol v-if="quickSelected" :medium="5">
				<GlobXpanel title="Snabbredigera">
					<div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'General'">
							<div class="ips-admin-quickedit">
								<label>
								{{$t('administration.article.fields.article_number')}}*
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'text'"
									v-model="dataObject.ArticleNumber"
									:disabled="true"
								/>
								</label>
								<label>
								{{$t('administration.article.fields.name')}}*
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'text'"
									v-model="dataObject.Name"
								/>
								</label>
								<label>
								{{$t('administration.article.fields.description')}}
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'text'"
									v-model="dataObject.Description"
								/>
								</label>
								<button :disabled="!dataObject.Name" @click="save" class="button">
								{{$t('general.button.save')}}
								</button>
								<button @click="quickSelected = false; dataObject = {};" class="button button--orange">
								{{$t('general.button.cancel')}}
								</button>
							</div>
						</GlobXpanel>
					</div>
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.article.picture')">
						<div class="kits-dropzone" v-dropzone="{ callback: (data) => log(data) }">
							<img v-if="img" class="kits-edit-article__image" :src="img" /><br />
							<input ref="fileInput" type="file" accept="image/*" @change="addImage($event.target.files)" />
						</div>
						<button :disabled="!file" @click="uploadImage" class="button button--margin">{{$t('administration.article.save_image')}}</button>
							<button :disabled="!img" @click="deleteImage" style="margin-left: 1rem;" class="button button--red button--margin">
							{{$t('administration.article.remove_image')}}
							</button>
						</GlobXpanel>
					</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create article modal -->
<GlobModal :widthInPx="1000" :title="$t('administration.article.create')" @close="showCreateArticleModal = false" :show="showCreateArticleModal" :isCRUD="true"
	v-if="showCreateArticleModal">
	<CreateArticle @articleCreated="(article) => articleCreated(article)" />
</GlobModal>
<!-- Edit article modal -->
<GlobModal :widthInPx="1000" :title="selectedArticle.Name ? selectedArticle.Name : selectedArticle.ArticleNumber" @close="selectedArticle = null" :show="selectedArticle && selectedArticle.Id && !hideEditModal" v-if="selectedArticle && selectedArticle.Id" :isCRUD="true">
	<EditArticle 
		:articleId="selectedArticle.Id" 
		:loading="articleModalLoading"
		:reloadData="reloadArticleEdit"
		@articleUpdated="(article) => articleUpdated(article)"
		@showArticleArchiveConfirmModal="showArticleArchiveConfirmModal = true"
		@showArticleUnArchiveConfirmModal="showArticleUnArchiveConfirmModal = true"
		@showArticleDeleteConfirmModal="showArticleDeleteConfirmModal = true"/>
</GlobModal>

	<!-- Archive confirm modal -->
	<GlobConsequence :show="showArticleArchiveConfirmModal" 
		@close="showArticleArchiveConfirmModal=false"  
		@confirm = "archiveOrUnarhiveArticle(true)"
		:title="$t('administration.article.archive_article')" 
		:message="$t('administration.article.archive_confirm')"  />


	<!-- Un archive  confirm modal -->
	<GlobConsequence :show="showArticleUnArchiveConfirmModal" 
		@close="showArticleUnArchiveConfirmModal=false"  
		@confirm = "archiveOrUnarhiveArticle(false)"
		:title="$t('administration.article.un_archive_article')" 
		:message="$t('administration.article.un_archive_confirm')"  />

	<!-- Delete confirm modal -->
	<GlobConsequence :show="showArticleDeleteConfirmModal" 
		@close="showArticleDeleteConfirmModal=false"  
		@confirm = "deleteArtcile()"
		:title="$t('administration.article.delete_article')" 
		:message="$t('administration.article.delete_confirm')"  />
</template>

<script>
import { onMounted, computed, watch, ref, watchEffect } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import CreateArticle from "@/views/crud/Article/Create.vue";
import EditArticle from "@/views/crud/Article/Edit.vue";

export default {
components: {
	Navbar,
	CreateArticle,
	EditArticle
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.articles);
    
	const selectedArticle = ref(null);

	const showCreateArticleModal = ref(false);
	const showArticleArchiveConfirmModal = ref(false);
	const showArticleUnArchiveConfirmModal = ref(false);
	const showArticleDeleteConfirmModal = ref(false)
	const reloadArticleEdit = ref(false);
	const articleModalLoading = ref(false);
	const hideEditModal = computed(() =>{
		return showArticleArchiveConfirmModal.value || showArticleUnArchiveConfirmModal.value || showArticleDeleteConfirmModal.value ? true : false;
	})


	const file = ref(null);
	 const fileInput = ref(null);
	const isLoading = ref(false);
	const notifications = useNotifications();
	const showArchived = ref(false);
	const loaded = ref(false);
	const searchString = ref('');
	const articleList  = ref([]);
	const articleListHead = ref([
			{ Alias: "GroupAmount", Value: "" },
			{ Alias: "ImagePath", Value: "" },
			{
				Alias: "ArticleNumber",
				Value: computed(() => tm('administration')['article']['fields']['article_number'])
			},
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['article']['fields']['name'])
			},
			{
				Alias: "Description",
				Value: computed(() => tm('administration')['article']['fields']['description'])
			},
			{
				Alias: "IsArchived",
				Value: computed(() => tm('administration')['article']['fields']['is_archived'])
			},
		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					selectedArticle.value = obj;
				}
		},
		{
			name: tm('general')['button']['quick_edit'],
			color: "blue",
			action: (obj) => {
				setEditor(obj);
			}
		},
		];
	});
	const renderFunctions = computed(()=> {
		return {
			ImagePath(obj){
				return `<i class="fas fa-${obj.ImagePath ? 'image' : ''}"></i>`;
			},
			GroupAmount(obj){
				return `<i title="${obj.GroupAmount > 0 ? 'Artikeln finns i grupp' : ''}" class="fas fa-${obj.GroupAmount > 0 ? 'object-group' : ''}"></i>`;
			},
			IsArchived(obj){
				return obj.IsArchived ? tm('general.yes') : tm('general.no');
			}
		}
	})
	
	 onMounted(async() => {
          loadArticles();
	 });

	const loadArticles = async(pagenumber) => {
		isLoading.value = true;
		endpoint.value.get({showArchived: showArchived.value, searchString: searchString.value, limit:20, page: pagenumber ? pagenumber : 0}).then(function(res){
           articleList.value = res.data;
		   paginationCurrentPage.value = res.currentPage + 1;
		   paginationLastPage.value = res.pages;
		   isLoading.value = false;
		})
	}

	const filteredArticles = computed(() => {
		return articleList.value;
	})

	const dataObject = ref({});
	const quickSelected = ref(false);
	const setEditor = (obj) => {
		dataObject.value.Id = obj.Id;
		dataObject.value.Name = obj.Name;
		dataObject.value.ArticleNumber = obj.ArticleNumber;
		dataObject.value.Description = obj.Description;
		dataObject.value.ImagePath = obj.ImagePath;
		quickSelected.value = true;
    };

	const img = computed(() => (file.value ? URL.createObjectURL(file.value) : dataObject.value.ImagePath));

	 const formData = new FormData();
	 const addImage = (files) => {
		file.value = files[0];
		formData.append("image", files[0]);
    };

	const save = () => {
		endpoint.value.update(dataObject.value.Id, dataObject.value).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['article']['update_success']
					},
					"success",
					false
			);
			articleUpdated(dataObject.value);
			dataObject.value = {};
			quickSelected.value = false;
			
		});
	};

	const articleCreated = (article) => {
		showCreateArticleModal.value = false;  
		selectedArticle.value = article;
		loadArticles();
	}

	const articleUpdated = (article) => {
		let articleIndex = articleList.value.findIndex((i) => i.Id == article.Id)
		articleList.value[articleIndex].ArticleNumber = article.ArticleNumber;
		articleList.value[articleIndex].Name = article.Name;
		articleList.value[articleIndex].IsArchived = article.IsArchived;
		articleList.value[articleIndex].Description = article.Description;
		articleList.value[articleIndex].GroupAmount = article.GroupAmount;
		articleList.value[articleIndex].ImagePath = article.ImagePath;
	}

	const archiveOrUnarhiveArticle = (flag) => {
		showArticleUnArchiveConfirmModal.value = false;
		showArticleArchiveConfirmModal.value = false;
		articleModalLoading.value = !articleModalLoading.value;
		endpoint.value.archiveOrUnarhiveArticle(selectedArticle.value.Id).then((res) => {
			isLoading.value = false;
			notifications.push(
					{
						msg: flag == 1 ? tm('administration')['article']['archive_success'] : tm('administration')['article']['un_archive_success']
					},
					"success",
					false
			);
			reloadArticleEdit.value = !reloadArticleEdit.value;
			selectedArticle.value.IsArchived = flag;
			
			let articleIndex = articleList.value.findIndex((i) => i.Id == selectedArticle.value.Id)
			articleList.value[articleIndex].IsArchived = flag;
		});
	}

	const deleteArtcile = () => {
		showArticleDeleteConfirmModal.value = false;
		articleModalLoading.value = !articleModalLoading.value;
		endpoint.value.delete(selectedArticle.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['article']['delete_success']
					},
					"success",
					false
			);
			articleList.value = articleList.value.filter((i) => i.Id != selectedArticle.value.Id);
			selectedArticle.value = null;
		});
	}

	const uploadImage = () => {
		isLoading.value = true;
		endpoint.value.uploadImage(dataObject.value.Id, file.value).then((res) => {
			getArticle(dataObject.value.Id)
			quickSelected.value = false;
			dataObject.value = {};
			file.value = null;
			fileInput.value = null;
		});
    };

	const getArticle = (articleId) => {
		endpoint.value.getById(articleId).then(function(res){
			articleUpdated(res.data)
			isLoading.value = false;
		});
	}

	const deleteImage = () => {
	  isLoading.value = true;
      endpoint.value.deleteImage(dataObject.value.Id).then((res) => {
		  getArticle(dataObject.value.Id)
		  quickSelected.value = false;
		  file.value = null;
		  fileInput.value = null;
		  dataObject.value = {};
      });
    };

	 watchEffect(() => {
      if (fileInput.value) {
        fileInput.value = null;
      }
    });

	watch(
      () => showArchived.value,
      (newValue, oldValue) => {
		  loadArticles();
      }
    );

	watch(
      () => searchString.value,
      (newValue, oldValue) => {
		  loadArticles();
      }
    );
	


	//Pagination

	const paginationCurrentPage = ref(0);
	const paginationLastPage = ref(0);

	const jumpToPage = (pageNumber) => {
		loadArticles(pageNumber - 1);
	}

    return {
		loadArticles,
		filteredArticles,
		endpoint,
		articleList,
		articleListHead,
		renderFunctions,
		searchString,
		actions,
		routes,
		loaded,
		showArchived,
		quickSelected,
		dataObject,
		save,
		isLoading,
		file,
		img,
		uploadImage,
		deleteImage,
		addImage,
		selectedArticle,
		hideEditModal,
		showCreateArticleModal,
		articleCreated,
		//archive
		showArticleArchiveConfirmModal,
		showArticleUnArchiveConfirmModal,
		archiveOrUnarhiveArticle,
		reloadArticleEdit,
		articleModalLoading,
		//delete
		showArticleDeleteConfirmModal,
		deleteArtcile,
		articleUpdated,
		//pagination
		paginationCurrentPage,
		paginationLastPage,
		jumpToPage
    };
  }
};
</script>