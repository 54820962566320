<template>
    <GlobXpanel :title="$t('home.need_attention.heading')">
         <GlobTable
             :maxHeight="50"
             :data="{
               body: data,
               head: head,
             }"
           />
    </GlobXpanel>
  </template>
  
  <script setup>
 
     import { computed } from 'vue';
     import { useI18n } from 'vue-i18n';
     const { tm } = useI18n();
     
     const props = defineProps({
     data: {
         type: Array,
         required: true
     }
     });
 
     const head = computed(() => {
         return [
            { Alias: "LockerName", Value: tm('home.need_attention.locker') },
            { Alias: "CustomerName", Value: tm('home.need_attention.customer') },
             { Alias: "ArticleName", Value: tm('home.need_attention.article') },
             { Alias: "ArticleNumber", Value: tm('home.need_attention.article_number') },
             { Alias: "CurrentVolume", Value: tm('home.need_attention.amount') },
             { Alias: "Diff", Value: tm('home.need_attention.diff') },
             { Alias: "Limit", Value: tm('home.need_attention.limit') },
             
         ];
     });
  
  </script>