<template>
  <tbody v-if="enableEditing" class="ips-table-body">
    <tr v-for="item in localData" :key="generateId(item.Id)" class="ips-table-body__row">
      <!-- Item -->
      <td v-for="(entry, index) in data.head" :key="generateId(entry.Alias)">
        <template v-if="getEditableField(entry)">
          <span v-if="getEditableField(entry).textForTrueStatement && item[entry.Alias]">{{ getEditableField(entry).textForTrueStatement }}</span>
          <template v-else>
            <InputCheckbox
				:id="'table-checkbox-'+ entry.Alias+'-'+index"
				:modelValue="item[entry.Alias] ? true : false"
				@update:modelValue="(event) => checkBoxChanged(event, item, entry.Alias)"
				v-if="getEditableField(entry).type == 'checkbox'"/>
            <input
              style="padding: .25rem .5rem;"
              @input="$emit('change-item', item)"
              v-model="item[entry.Alias]"
              :type="getEditableField(entry).type"
              v-else
            />

          </template>
        </template>
        <span v-else v-html="renderer.render(item, entry.Alias)"></span>
      </td>
      <!-- Actions -->
      <td v-if="actions">
        <span v-for="action in actions" :key="generateId(action.name)">
          <span v-if="action.if ? (typeof action.if == 'function' ? action.if(item) : action.if) : true">
            <button
              class="button button--outline button--small button--action"
              @click="execAction(action, item, $event)"
              :title="action.description && action.description"
              :class="generateButtonClasslist(action, item)"
            >
              {{ action.name }}
            </button>
          </span>
        </span>
      </td>
    </tr>
  </tbody>
  <tbody v-else class="ips-table-body">
    <tr v-for="item in localData" :key="generateId(item.Id)" class="ips-table-body__row">
      <!-- Item -->
      <td v-for="entry in data.head" 
        :key="generateId(entry.Alias)" 
        :style="{
            backgroundColor: renderer.getTableCellColor(entry, item, 'BgColor'), 
					}"
        v-html="renderer.render(item, entry.Alias)"
        ></td>
      <!-- Actions -->
      <td v-if="actions">
        <span v-for="action in filteredActions" :key="generateId(action.name)">
          <button
            class="button button--outline button--small button--action"
            @click="execAction(action, item, $event)"
            :title="action.description && action.description"
            :class="generateButtonClasslist(action, item)"
          >
            {{ action.name }}
          </button>
        </span>
      </td>
    </tr>
  </tbody>
  <GlobModal
    :width="300"
    :widthInPx="true"
    :isLoaded="activeAction && activeAction.isLoaded"
    :show="activeAction && activeAction.isLoaded"
    @close="activeAction = null"
    title="Är du säker?"
  >
    <p
      style="margin: .5rem 0 0 0; text-align: center;"
      v-html="activeAction.action.requirements.consequence(activeAction.item)"
    ></p>
    <div style="display: flex; margin: 0 1rem;">
      <button
        class="button button--red button--margin"
        @click="doFinalAction()"
        :title="activeAction.action.description"
      >
        Ja
      </button>
      <button class="button button--orange button--margin" style="margin-left: auto;" @click="activeAction = null">
        Nej
      </button>
    </div>
  </GlobModal>
</template>

<script>
import { useRenderFunctions, useItemRenderer } from "@/components/globals/table/common";
import { generateId } from "@/services/utils";
import { reactive, ref, onMounted, computed, watchEffect } from "vue";
export default {
  emits: ["change-item"],
  props: ["data", "itemRenderFunctions", "enableRenderFunctions", "actions", "enableEditing", "fields", "sortingData"],
  setup(props, { emit }) {
		const { sortingData } = reactive(props);
    const generateButtonClasslist = (action) => {
      const style = `button--${action.buttonStyle}`;
      const color = `button--${action.color}`;
      return style + " " + color;
    };

    const renderFunctions = props.itemRenderFunctions || (props.enableRenderFunctions && useRenderFunctions());
    const renderer = useItemRenderer(renderFunctions);

    //Actions
    let visibleReqs = reactive([]);
    let activeAction = ref(null);
    const execAction = (action, item, e) => {
      e.stopPropagation();
      if (action.requirements) {
        activeAction.value = {
          isLoaded: true,
          action,
          item,
        };
      } else {
        action.action(item);
      }
    };
    const doFinalAction = () => {
      activeAction.value.action.action(activeAction.value.item);
      activeAction.value = null;
    };

    const getEditableField = (item) => {
      const field = props.fields.find((field) => field.key == item.Alias);
      if (field) {
        return field;
      } else {
        return false;
      }
    };

    const localData = computed(() => {
	 let data = [];
      if (props.sortingData.key) {
        data = props.data.body
          .map((i) => Object.assign({}, i))
          .sort((a, b) => {
            if (a[props.sortingData.key] < b[props.sortingData.key]) {
              return props.sortingData.descending ? -1 : 1;
            }
            if (a[props.sortingData.key] > b[props.sortingData.key]) {
              return props.sortingData.descending ? 1 : -1;
            }
            return 0;
          });
      } else {
        data = props.data.body.map((i) => Object.assign({}, i));
      }

	  emit('localData' , data);
	  return data;
    });

    const log = (val) => {
      console.log(val);
    };

    const filteredActions = computed(() => props.actions.filter((item) => (item.if ? item.if() : true)));

	const checkBoxChanged = (val, item, key) => {
		item[key] = val;
      	emit("change-item", item);
    };

    return {
      renderer,
      generateButtonClasslist,
      generateId,
      execAction,
      activeAction,
      doFinalAction,
      getEditableField,
      localData,
      log,
      filteredActions,
      checkBoxChanged
    };
  },
};
</script>

<style lang="scss">
.ips-table-body {
  &__row {
    &:nth-child(odd) {
      background-color: $kits-white;
    }
    &:hover {
	  cursor: pointer;
	  border: 1px solid $kits-ShakespeareBlue;
      background-color: darken($kits-lightGray, 5%);
      .button--outline{
        background: linear-gradient(271.74deg, $kits-parisGreen 1.47%, $kits-freshGreen 121.08%);
        color: $kits-white !important;
        border: none !important;
		&.button--red {
			background: linear-gradient(93.6deg, $kits-crimsonRed 1.18%, $kits-venetianRed 98.62%);
		}
		&.button--blue {
			background: linear-gradient(270deg, $kits-congressBlue -3.62%, $kits-ShakespeareBlue 93.48%);
		}
      }
    }
    td {
      padding: $cellpadding;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: $kits-carbonGrey;
      font-family: 'Inter';
      font-style: normal;
	    padding-left: 25px;

      i.fa-image{
        color: $kits-ShakespeareBlue;
      }
    }
    background-color: $kits-lightGrayV1;
    height: 48px;
  }

}
</style>
